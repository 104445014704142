<template>
  <div class="home">
    <mushroom-widget/>
  </div>
</template>

<script>
import MushroomWidget from '../components/MushroomWidget.vue'

export default {
  name: 'Home',
  components: {
    MushroomWidget
  }
}
</script>

